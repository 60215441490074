/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import { loadRefreshList } from '@/assets/app.js';
import listMore from '@/components/common/listMore';
import instStatusColor from '@/assets/instStatusColor.js';
Vue.component(listMore.name, listMore);
export default {
  name: 'msg-list',
  data() {
    return {
      list: [],
      pageIndex: 0,
      pageSize: 20,
      total: 0
    };
  },
  props: {
    tabKey: String,
    index: [String, Number]
  },
  computed: {
    treeIds() {
      return this.$store.state.searchModule.instSearchTreeIds;
    },
    searchVal() {
      return this.$store.state.searchModule.instSearchVal;
    }
  },
  watch: {
    treeIds: function (val, oldVal) {
      if (this.$parent.index == this.index) {
        this.loadList(true, false);
      }
    },
    searchVal: function (val, oldVal) {
      if (this.$parent.index == this.index) {
        this.loadList(true, false);
      }
    }
  },
  created() {
    this.loadList(true, false);
  },
  methods: {
    refreshList() {
      this.loadList(true, false);
    },
    loadList(initPage, merge) {
      var url = _baseUrl + "/oa/info/infInbox/received.do";
      if (initPage) {
        this.pageIndex = 0;
      } else {
        this.pageIndex++;
      }
      var params = "pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;
      params = this.buildQuery(params);
      loadRefreshList(url, params, this, data => {
        this.list = data.data;
        this.total = data.total;
      }, merge);
    },
    buildQuery(params) {
      var searchVal = this.$store.state.searchModule.instSearchVal;
      if (searchVal) {
        params += "&Q_sender_S_LK=" + searchVal;
      }
      return params;
    },
    getStatus(e) {
      return e == 'yes' ? '<span style="color: #67C23A;">已读</span>' : '<span style="color: #F56C6C;">未读</span>';
    }
  }
};