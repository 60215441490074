/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
export default {
  name: 'solBox',
  props: {
    solPar: [Object, Boolean, String]
  },
  data() {
    return {
      isOpen: false,
      sonSize: 0,
      isChoose: false
    };
  },
  created() {},
  computed: {},
  methods: {
    choose() {
      this.$parent.allSel = false;
      this.isChoose = !this.isChoose;
    }
  },
  watch: {}
};